
import BaseInputOutlined from '@/components/inputs/BaseInputOutlined.vue'

import { computed, defineComponent, ref, onMounted, reactive } from 'vue'
import api from '../../../api/api'
import { useProject } from '../../../hooks/useProject'
import { useDateMenu } from '../../../hooks/useDateMenu'
import moment from 'moment'
import Breadcrumbs from '../../../components/Breadcrumbs.vue'
import SpinnerLoader from '../../../components/SpinnerLoader.vue'
import { mapFilterToObjectFormat, mapFilterFromObjectFormat, getFilterListFromObjectFormat } from '@/utils'
import { downloadFile } from '@/utils'
import CustomActivatorSelect from '../../../components/inputs/CustomActivatorSelect.vue'
import StatisticsTable from '../components/StatisticsTable.vue'
export default defineComponent({
    components: {
        BaseInputOutlined,
        Breadcrumbs,
        SpinnerLoader,
        CustomActivatorSelect,
        StatisticsTable,
    },
    props: {},
    setup(props, { emit }) {
        const { project } = useProject()
        const loading = ref(true)
        const reportData = ref<any>({})
        const filters = ref({
            activationNameList: {
                selected: [] as string[],
                list: [] as any[],
            },
            projectCommunicationNameList: {
                selected: [] as string[],
                list: [] as any[],
            },
        })
        const dateMenu = reactive(
            useDateMenu({
                dateFormat: 'DD.MM.YYYY',
                defaultStartDate: moment().subtract(1, 'month'),
                defaultEndDate: moment(),
            })
        )
        const breadcrumbs = computed(() => [
            {
                text: 'ВАШИ ПРОЕКТЫ',
                to: '/projects',
            },
            {
                text: project.value.name,
                to: `/project/${project.value.id}`,
            },
            {
                text: 'Отчет по активациям',
                to: '',
                disabled: true,
            },
        ])

        const initData = async (includeFileLink?: boolean) => {
            loading.value = true
            const { data, error } = await api.gcb2.getAdminReportPseudoActivationReport({
                projectId: project.value.id,
                includeFileLink,
                filters: {
                    minDate: dateMenu.startDate,
                    maxDate: dateMenu.endDate,
                    activationNameList:
                        filters.value.activationNameList.selected &&
                        filters.value.activationNameList.selected.length !==
                            filters.value.activationNameList.list.length
                            ? mapFilterToObjectFormat(
                                  filters.value.activationNameList.selected,
                                  filters.value.activationNameList.list
                              )
                            : undefined,
                    projectCommunicationNameList:
                        filters.value.projectCommunicationNameList.selected &&
                        filters.value.projectCommunicationNameList.selected.length !==
                            filters.value.projectCommunicationNameList.list.length
                            ? mapFilterToObjectFormat(
                                  filters.value.projectCommunicationNameList.selected,
                                  filters.value.projectCommunicationNameList.list
                              )
                            : undefined,
                },
            })
            filters.value.activationNameList.list = getFilterListFromObjectFormat(
                data.filters.activationNameList
            )
            filters.value.activationNameList.selected = mapFilterFromObjectFormat(
                data.filters.activationNameList
            )

            filters.value.projectCommunicationNameList.list = getFilterListFromObjectFormat(
                data.filters.projectCommunicationNameList
            )
            filters.value.projectCommunicationNameList.selected = mapFilterFromObjectFormat(
                data.filters.projectCommunicationNameList
            )
            reportData.value = data.reportData
            loading.value = false
            if (data.fileSrc) {
                downloadFile(data.fileSrc, '')
            }
        }

        onMounted(() => {
            initData()
        })
        return { dateMenu, breadcrumbs, loading, filters, reportData, initData }
    },
})
